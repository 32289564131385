$primaryColor: #e892b6;		//tmavo modra
$secondaryColor: #05ca00;	//zelena pre hvoer
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;
