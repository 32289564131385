@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.awards {
	padding: 70px;
	@media screen and (max-width: 1550px) {
		padding: 0 15px; }
	@media screen and (min-width: 768px) {
		padding-top: 100px; }
	@media screen and (max-width: 1400px) {
		.col-md-6 {
			width: 100%; } }
	.main-bar {
		h1 {
			color: white;
			margin: 0;
			padding-top: 80px;
			font-size: 50px;
			letter-spacing: 1px; } }
	article {
		margin-bottom: 30px;
		width: 85%;
		h2,p {
			color: white;
			letter-spacing: 1px; }
		h2 {
			font-weight: 400; }
		p {
			margin-top: 15px;
			line-height: 20px;
			text-align: justify;
			letter-spacing: 1px; }
		.image-wrapp {
			height: 150px;
			width: 140px;
			display: inline-block;
			overflow: hidden;
			a {
				display: inline-block;
				height: 150px;
				width: 140px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				transition: .3s;
				-webkit-transition: .3s;
				&:hover {
					transform: scale(1.1,1.1);
					opacity: 0.8; } } } } }
