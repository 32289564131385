@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }


@import 'master';


html, body, div, span, applet, object, iframe,p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block; }
body {
	line-height: 1; }
ol, ul {
	list-style: none; }
blockquote, q {
	quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none; }
h2 {
	color: #2b2829;
	font-weight: 900; }
p {
	color: #2b2829; }
table {
	border-collapse: collapse;
	border-spacing: 0; }
a {
	color: white;
	&:focus {
		color: $primaryColor;
		text-decoration: none; } }
// CSS reset end

body {
	font-family: 'Encode Sans Condensed', sans-serif;
	.wrapper {
		width: 100%;
		min-height: 880px;
		height: 100vh;
		display: table;
		header {
			display: table-cell;
			width: 330px;
			min-height: 880px;
			height: 100%;
			background-color: black;
			position: relative;
			z-index: 9999;
			#navbar-open,
			#navbar-close {
				color: white;
				display: none;
				width: 45px;
				height: 45px;
				background: black;
				text-align: center;
				padding: 10px;
				font-size: 25px;
				position: absolute;
				right: -45px;
				margin: 0;
				&:hover,
				&:focus {
					color: white; }
				a {
					display: none;
					img {
						width: 20px;
						margin: 15px 0 0; } } }
			@media screen and (max-width: 1023px) {
				position: fixed;
				width: 230px;
				left: -230px;
				top: 0;
				#navbar-open {
					display: block; } }
			@media screen and (max-width: 767px) {
				#navbar-open,
				#navbar-close {
					height: 100%;
					padding-top: 180px;
					a {
						display: block; } } }

			@media screen and (min-width: 1024px) {
				left: 0 !important;
				#navbar-open,
				#navbar-close {
					display: none !important; } }
			#logo {
				display: block;
				text-align: center;
				margin: 40px auto 50px;
				width: 174px;
				span {
					display: block;
					text-transform: uppercase;
					color: white;
					font-size: 23px;
					margin: 15px auto;
					transition: .3s;
					-webkit-transition: .3s; }
				img {
					position: relative;
					left: 47px;
					@media screen and (max-width: 1023px) {
						width: 85%;
						left: 38px; } }
				&:hover,
				&:focus {
					text-decoration: none;
					span {
						color: $primaryColor; } }
				@media screen and (max-width: 767px) {
					display: none; } }
			nav {
				>ul {
					margin: 70px auto;
					width: 100%;
					display: block;
					> li {
						display: block;
						padding-left: 40%;
						float: none;
						font-size: 17px;
						> a {
							color: white;
							margin-bottom: 38px;
							text-transform: uppercase;
							padding: 0;
							-webkit-transition: .3s;
							transition: .3s;
							text-shadow: none;
							letter-spacing: 0.7px;
							&:hover,
							&:focus {
								background-color: transparent;
								color: $primaryColor; } }
						ul {
							display: none;
							li {
								font-size: 15px;
								text-transform: uppercase;
								a {
									display: block;
									color: #727272;
									margin-bottom: 16px;
									-webkit-transition: .3s;
									transition: .3s;
									font-weight: 500;
									letter-spacing: 1px;
									font-size: 16px;
									&:hover {
										color: $primaryColor;
										text-decoration: none; } } }
							li.last {
								margin-bottom: 38px; } } }
					.active {
						>a {
							color: $primaryColor; } }
					.parent {
						ul {
							display: block; } }
					.has-submenu {
						position: relative;

						.fa {
						    position: absolute;
						    top: 3px;
						    color: white;
						    right: 120px;
						    font-size: 13px;
						    cursor: pointer;
						    &:hover {
						    	color: $primaryColor; }
						    @media screen and (max-width: 1023px) {
						    	right: 70px; } }
						.fa-chevron-up {
							display: none; } }
					.active {
						ul {
							display: block; }
						.fa-chevron-up {
							display: block; }
						.fa-chevron-down {
							display: none; } } } }
			#lang {
				display: block;
				width: 100%;
				text-align: center;
				margin-top: 130px;
				margin-bottom: 20px;
				li {
					display: inline-block;
					a {
						text-transform: uppercase;
						color: white;
						font-size: 15px;
						display: block;
						height: 35px;
						width: 35px;
						padding: 10px;
						-webkit-transition: .3s;
						transition: .3s;
						&:hover,
						&:focus {
							background-color: white;
							color: $primaryColor;
							text-decoration: none; } } }
				.selected {
					a {
						background-color: $primaryColor;
						color: black;
						text-decoration: none; } } } }

		#slider {
			position: fixed;
			height: 100%;
			width: 100%;
			button {
				display: none; }
			.slider {
				background-position: center left;
				background-size: cover;
				height: 100vh;
				width: 100%;
				display: block; } }
		main {
			// background-image: url(..//images/layout/house.png)
			width: calc(100% - 330px);
			height: 100%;
			// float: left
			background-repeat: no-repeat;
			display: table-cell;
			vertical-align: top;
			position: relative;

			#show_contact {
				display: none;
				background-color: black;
				color: white;
				height: 100%;
				width: 300px;
				padding-top: 26%;
				text-align: center;
				font-size: 35px;
				text-transform: uppercase;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 99;
				&:hover,
				&:focus {
					text-decoration: none; } }
			.content-flex {
				@media screen and (min-width: 768px) {
					padding-top: 100px; }
				color: white;
				.static-content {
					padding: 0 70px;
					@media screen and (max-width: 1550px) {
						padding: 0 15px; }
					.main-bar {
						h1 {
							margin: 0;
							font-weight: 300;
							text-transform: uppercase;
							font-size: 50px;
							margin-bottom: 60px;
							margin-top: 20px;
							@media screen and (max-width: 1540px) and (min-width: 768px) {
								margin-bottom: 30px;
								margin-top: 80px; }
							@media screen and (max-width: 767px) {
								margin-bottom: 30px;
								margin-top: 20px; } } }

					h2 {
						color: white;
						font-weight: 500;
						text-transform: uppercase;
						font-size: 27px;
						line-height: 30px;
						letter-spacing: 1px; }
					p, li {
						color: white;
						font-size: 17px;
						line-height: 25px;
						margin-bottom: 30px;
						letter-spacing: 1px; }
					ul {
						li {
							margin-bottom: 11px;
							line-height: 20px;
							&:before {
								content: "• ";
								color: $primaryColor;
								position: absolute;
								left: 0px;
								font-size: 22px; } } } } }
			.content-wrapp {
				width: 1220px;
				padding: 0 15px;
				height: 100%;
				background-color: rgba(0,0,0,0.9);
				position: relative;
				z-index: 1;
				@media screen and (max-width: 1550px) {
					width: 100%; }
				@media screen and (max-width: 1023px) {
					padding: 0 15px 0 60px; } }
			.clona {
				display: block;
				height: 100%;
				width: 40px;
				position: fixed;
				right: 0;
				top: 0;
				background: rgba(0,0,0,0.9);
				@media screen and (max-width: 1770px) {
					display: none; } } }
		.contact-panel {
			position: absolute;
			a {
				display: inline-block;
				margin: 20px 0 20px 60px;
				.phone-icon, .email-icon {
					width: 20px;
					height: 20px;
					position: relative;
					left: -10px;
					top: 5px;
					display: inline-block;
					background-position: center;
					background-repeat: no-repeat;
					transition: .3s;
					-webkit-transition: .3s; }
				.phone-icon {
					background-image: url(../images/icons/phone-icon.svg); }
				.email-icon {
					background-image: url(../images/icons/email-icon.svg); }
				span {
					color: white;
					font-size: 15px;
					transition: .3s;
					-webkit-transition: .3s; }
				&:hover,
				&:focus {
					text-decoration: none;
					span {
						color: $primaryColor; }
					.phone-icon {
						background-image: url(../images/icons/phone-hover.svg); }
					.email-icon {
						background-image: url(../images/icons/email-hover.svg); } } }
			@media screen and (max-width: 767px) {
				display: none; } } }
	.mobile-header {
		height: 170px;
		background-color: black;
		display: none;
		@media screen and (max-width: 767px) {
			display: block; }
		a {
			display: block;
			text-align: center;
			padding: 12px 0 0;
			img {
				width: 140px;
				position: relative;
				left: 35px; }
			span {
				display: block;
				text-transform: uppercase;
				color: white;
				font-size: 20px;
				width: 200px;
				margin: 3px auto;
				transition: .3s;
				-webkit-transition: .3s; }
			&:hover,
			&:focus {
				text-decoration: none;
				span {
					color: $primaryColor; } } } } }
/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	border: 1px dashed transparent;
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		// border: 1px solid transparentize(darken($warningColor, 30), 0.75)
		// background-color: transparentize(black, 0.95)
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }
.cookieinfo {
	background-color: black !important;
	color: white !important; }
.cookieinfo-close {
	background-color: $primaryColor !important;
	color: white !important; }

@media screen and (max-width: 768px) {
	nav {
		height: 100vh;
		overflow: auto; } }


@import 'footer';

@import 'modules/contact';
@import 'modules/gallery';
@import 'modules/home';
@import 'modules/templates';
@import 'modules/person';
@import 'modules/article';
