.translate-button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out,color .25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: .85em 1em;
  margin: 1rem 0 1rem;
  font-size: .9rem;
  background-color: #ed6c08;
  color: #fefefe;
  text-transform: uppercase; }

.translate-button:hover {
  background-color: #c95c07;
  color: #fefefe; }
