@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.reference-wrapp {
	height: 100vh;
	width: 100%;
	@media screen and (min-width: 768px) {
		padding-top: 100px; }
	.reference-flex {
		width: 90%;
		.main-bar {
			h1 {
				margin: 0;
				font-weight: 300;
				text-transform: uppercase;
				font-size: 50px;
				margin-bottom: 80px;
				color: white; } }
		.references {
			.reference-item {
				margin-bottom: 100px;
				display: block;
				h2 {
					font-size: 25px;
					text-transform: uppercase;
					width: 60%;
					margin-bottom: 23px;
					line-height: 25px;
					letter-spacing: 1px;
					font-weight: 400;
					a {
						transition: .3s;
						-webkit-transition: .3s;
						&:hover,
						&:focus {
							color: $primaryColor;
							text-decoration: none; } } }
				span {
					font-size: 15px;
					color: $primaryColor;
					display: block;
					margin-bottom: 20px; }
				p {
					color: white;
					font-size: 18px;
					line-height: 22px;
					letter-spacing: 1px; } } }
		.faq-detail {
			p {
				color: white; } } } }
.detail-page {
	padding: 0 70px;
	@media screen and (max-width: 1550px) {
		padding: 0 15px; }
	h1 {
		text-align: left;
		color: white;
		font-size: 30px;
		padding-top: 80px;
		margin-top: 0;
		font-weight: 300;
		letter-spacing: 1px; }
	p {
		letter-spacing: 1px;
		color: white;
		text-align: left;
		line-height: 20px; } }
