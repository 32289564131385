@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.gallery_wrapp {
	padding: 0 35px;
	line-height: 20px;
	height: 100%;
	position: relative;
	.main-bar {
		padding-top: 80px; }
	.categories {
		margin-bottom: 50px;
		a {
		    display: inline-block;
		    font-size: 17px;
		    text-transform: uppercase;
		    border: 2px solid #fff;
		    height: 70px;
		    padding: 24px;
		    margin-bottom: 7px;
		    margin-right: 7px;
		    -webkit-transition: .3s;
		    transition: .3s;
		    &:hover,
		    &:focus {
		    	background-color: $primaryColor;
		    	color: white;
		    	text-decoration: none;
		    	border: 2px solid $primaryColor; } }
		a[selected='selected'] {
			color: white;
			background-color: $primaryColor;
			border: 2px solid $primaryColor; } }

	.galerie {
		padding-top: 70px;
		a {
			span {
				display: block;
				font-size: 26px;
				line-height: 30px;
				text-transform: uppercase;
				height: 80px;
				letter-spacing: 1px; }
			&:hover,
			&:focus {
				text-decoration: none;
				span {
					color: $primaryColor;
					text-decoration: none; } } }
		.image-wrapp {
			width: 100%;
			height: 250px;
			overflow: hidden;
			margin: 15px 0 5px;
			.gallery-image {
				width: 100%;
				background-size: cover;
				height: 250px;
				display: block;
				background-repeat: no-repeat;
				transition: .5s;
				-webkit-transition: .5s;
				position: relative;
				&:hover {
					transform: scale(1.1,1.1);
					opacity: 0.8; } } } }
	.short_content {
		p {
			color: white;
			letter-spacing: 1px; } }
	.to-gallery {
		width: 200px;
		height: 70px;
		text-transform: uppercase;
		text-align: center;
		border: 3px solid $primaryColor;
		background-color: $primaryColor;
		float: right;
		display: block;
		font-size: 20px;
		padding: 22px;
		font-weight: 600;
		transition: .3s;
		-webkit-transition: .3s;
		&:hover,
		&:focus {
			text-decoration: none;
			background-color: transparent;
			border: 3px solid white;
			color: white; }
		@media screen and (max-width: 767px) {
			margin: 30px auto 0;
			float: none; } } }
.gallery-detail {
	margin-top: 30px;
	.image-wrapp {
		width: 100%;
		display: inline-block;
		overflow: hidden;
		margin-bottom: 30px;
		a {
			display: block;
			width: 100%;
			height: 250px;
			background-size: cover;
			background-repeat: no-repeat;
			transition: .3s;
			-webkit-transition: .3s;
			background-position: center;
			&:hover {
				transform: scale(1.1,1.1);
				opacity: 0.8; } } }
	@media screen and (max-width: 550px) {
		.col-xs-6 {
			width: 100%; } } }
.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #6d6e6f;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	position: absolute;
	left: 40%;
	top: 55%;
	display: none; }
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg); } }
@keyframes spin {
	0% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(360deg); } }
@media screen and (max-width: 1440px) {
	.gallery_wrapp {
		padding: 0;
		.categories {
			a {
				height: 45px;
				padding: 12px;
				margin-bottom: 5px;
				margin-right: 5px; } }
		.galerie {
			a {
				span {
					display: block;
					font-size: 15px;
					line-height: 21px;
					text-transform: uppercase;
					height: 50px; } } } } }

@media screen and (max-width: 767px) {
	.gallery_wrapp {
		.main-bar {
			h1 {
				padding-top: 20px; } }
		.categories {
			a {
				display: block;
				float: left;
				text-align: center; } } } }
@media screen and (max-width: 767px) and (min-width: 640px) {
	.gallery_wrapp {
		.categories {
			a {
				width: 32%;
				font-size: 14px;
				height: 55px; } } } }
@media screen and (max-width: 639px) and (min-width: 575px) {
	.loader {
		top: 60%; }
	.gallery_wrapp {
		.categories {
			a {
				display: block;
				width: 49%;
				float: left;
				height: 57px;
				font-size: 14px; } } } }
@media screen and (max-width: 574px) {
	.loader {
		top: 35%; }
	.gallery_wrapp {
		.categories {
			a {
				width: 100%;
				margin-right: 0;
				height: auto; } } } }
