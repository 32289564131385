@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

footer {
	color: white;
	text-transform: uppercase;
	text-align: center;
	font-size: 11px;
	line-height: 20px;
	font-weight: 600;
	display: block;
	width: 100%;
	margin-bottom: 20px;
	a {
		color: white;
		&:hover,
		&:focus {
			color: $primaryColor;
			text-decoration: none; } }
	a.sixnet {
		color: $primaryColor;
		&:hover,
		&:focus {
			color: white; } }
	span {
		font-size: 13px; } }






