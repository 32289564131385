@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

body {
	.wrapper {
		main {
			.personal {
				padding: 0 60px;
				@media screen and (max-width: 1023px) {
					padding: 0 15px; }
				@media screen and (max-width: 1400px) {
					.col-md-6 {
						width: 100%; } }
				.main-bar {
					h1 {
						margin: 0;
						font-weight: 300;
						text-transform: uppercase;
						font-size: 50px;
						margin-bottom: 60px;
						padding-top: 80px;
						color: white;
						letter-spacing: 1px; } }
				h2 {
					color: #fff;
					font-weight: 400;
					text-transform: uppercase;
					margin-bottom: 30px;
					margin-top: 0;
					letter-spacing: 1px;
					&:first-child {
						padding-top: 80px; }
					p {
						color: #fff; } }
				.person-profile {
					margin-bottom: 15px;
					min-height: 330px;
					img {
						margin-bottom: 30px;
						display: block;
						max-width: 100%; } }
				.person, .coop {
					h3,p, strong, span {
						margin: 0;
						color: white;
						letter-spacing: 1.5px; }
					p {
						margin-bottom: 10px;
						margin-top: 4px;
						line-height: 20px; }
					h3 {
						font-weight: 400;
						font-size: 30px; }
					span {
						color: white;
						display: block;
						margin-bottom: 15px;
						margin-top: 5px; }
					a {
						display: inline-block;
						margin-top: 10px;
						margin-right: 10px;
						transition: .3s;
						-webkit-transition: .3s;
						&:hover,
						&:focus {
							text-decoration: none;
							color: $primaryColor; } } }
				.coop {
					margin-bottom: 30px; } } } } }
