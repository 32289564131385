@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.home-wrapp {
	// max-width: 768px
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	h1 {
		color: white;
		font-size: 49px;
		font-weight: 500;
		margin-bottom: 90px;
		letter-spacing: 2px;
		span {
			display: block; }
		span.thin {
			font-weight: 300; } }
	.row {
		margin-bottom: 20px; }
	.main-buttons {
		p, a {
			color: white; }
		a {
			width: 100%;
			height: 70px;
			text-align: center;
			border: 3px solid;
			display: block;
			font-size: 20px;
			padding: 22px;
			font-weight: 600;
			transition: .3s;
			-webkit-transition: .3s;
			&:hover,
			&:focus {
				background-color: $primaryColor;
				border: 3px solid $primaryColor;
				text-decoration: none; } } }

	.introduction {
		margin-bottom: 60px;
		margin-top: 20px;
		.column {
			a {
				font-size: 25px;
				position: relative;
				display: block;
				transition: .2s;
				-webkit-transition: .2s;
				&:after {
					display: block;
					width: 11px;
					height: 21px;
					background-repeat: no-repeat;
					background-image: url(../images/icons/arrow-right.png);
					content: " ";
					position: absolute;
					right: 0;
					top: 15px; }
				&:hover,
				&:focus {
					color: $primaryColor;
					text-decoration: none; } }
			@media screen and (max-width: 1130px) {
				a {
					margin-bottom: 20px;
					&:after {
						display: none; } } } }
		.column:last-child {
			a:after {
				display: none; } } }
	@media screen and (max-width: 1130px) {
		h1 {
			font-size: 40px; } }
	@media screen and (max-width: 767px) {
		align-items: start;
		h1 {
			font-size: 30px;
			margin-top: 25px; }
		.introduction {
			margin-bottom: 0;
			.column {
				a {
					font-size: 17px; } } }
		.service {
			margin-bottom: 20px; } } }
