@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

body.contact {
	.page-contact {
		position: relative;
		a {
			transition: .3s;
			-webkit-transition: .3s;
			&:hover,
			&:focus {
				color: $primaryColor;
				text-decoration: none; } } }
	.static-content {
		width: 90%;
		padding: 0 !important;
		h2 {
			font-weight: 600;
			margin-bottom: 25px; } }
	form {
		position: relative;
		label {
			font-size: 18px;
			letter-spacing: 0.9px;
			span {
 } }					// color: $primaryColor
		input,textarea {
			width: 100%;
			border: none;
			color: black;
			padding: 8px; }
		input {
			height: 30px;
			margin-bottom: 7px; }
		textarea {
			height: 115px;
			border-radius: 0; }
		button {
			display: block;
			border: 2px solid;
			border-radius: 0;
			font-size: 20px;
			text-transform: uppercase;
			width: 122px;
			height: 50px;
			transition: .3s;
			-webkit-transition: .3s;
			position: absolute;
			right: 0;
			bottom: 1px;
			&:hover {
				background-color: $primaryColor;
				color: white;
				border: 2px solid $primaryColor; } }
		@media screen and (max-width: 1450px) {
			padding-bottom: 100px;
			button {
				position: static;
				float: right;
				margin-top: 15px; } }
		@media screen and (max-width: 1450px) and (min-width: 992px) {
			.col-md-4, .col-md-5 {
				width: 50%; } }
		@media screen and (max-width: 767px) {
			label {
				margin-bottom: 6px; } } }
	#show-map {
		width: 300px;
		height: 70px;
		text-align: center;
		border: 3px solid;
		display: block;
		font-size: 20px;
		padding: 22px;
		font-weight: 600;
		transition: .3s;
		-webkit-transition: .3s;
		position: absolute;
		bottom: 0;
		right: 0;
		&:hover,
		&:focus {
			background-color: $primaryColor;
			color: white;
			text-decoration: none;
			border: 3px solid $primaryColor; }
		@media screen and (max-width: 1600px) {
			display: none; } }
	@media screen and (max-width: 1150px) and (min-width: 768px) {
		.wrapper {
			main {
				.content-flex {
					.static-content {
						.main-bar {
							h1 {
								margin-bottom: 30px;
								margin-top: 80px; } } } } } } }
	@media screen and (max-width: 767px) {
		.wrapper {
			main {
				.content-flex {
					.static-content {
						.main-bar {
							h1 {
								margin-bottom: 30px;
								margin-top: 20px; } } } } } } } }
.small-map {
	margin-top: 30px;
	display: none;
	@media screen and (max-width: 1600px) {
		display: block; } }
